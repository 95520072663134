<template lang="pug" functional>
  div
    v-card.employees-skeleton(v-for="i in props.count" :key="i")
      .employees-table__row.employees-table__row--body
        .employees-table__row-head
          .employees-table__column
            .text-skeleton
          .employees-table__column
            .text-skeleton
          .employees-table__column
            .text-skeleton

</template>

<script>
export default {
  name: 'EmployeesTableRowSkeleton',

  props: {
    count: {
      type: Number,
      default: 1
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '@/sass/abstracts/_mixins.scss';
  @import '@/sass/abstracts/_variables.scss';

  .employees-skeleton {
    &:not(:last-child) {
      margin-bottom: 1rem;
    }
  }

  .text-skeleton {
    height: 2rem;
    width: 60%;
    border-radius: .8rem;

    @include skeleton;
  }
</style>
